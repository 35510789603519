import request from '@/utils/request.js'

export function pageInit(){
    return request({
        method:'get',
        url:'/api/financeBillCollection/pageInit',
    });
}

export function listPage(params){
    return request({
        method:'get',
        url:'/api/financeBillCollection/listPage',
        params
    })
}

export function batchUpdate(data){
    return request({
        method:'put',
        url:'/api/financeBillCollection/batchUpdate',
        data
    })
}

// 新增初始化
export function addInit(){
    return request({
        method:'get',
        url:'/api/financeBillCollection/addInit'
    });
}

export function addCollect(data){
    return request({
        method:'post',
        url:'/api/financeBillCollection',
        data
    });
}

export function del(id){
    return request({
        method:'delete',
        url:`/api/financeBillCollection/${id}`,
    });
}

export function changeInit(id){
    return request({
        method:'get',
        url:`/api/financeBillCollection/updateInit/${id}`
    });
}
// 修改
export function change(data){
    return request({
        method:'put',
        url:'/api/financeBillCollection',
        data
    });
}

export function addtype(data){
    return request({
        method:'put',
        url:'/api/financeBillCollection/addOrUpdateCategory',
        data
    });
}

export function delType(id){
    return request({
        method:'delete',
        url:`/api/financeBillCollection/deleteCategory/${id}`
    });
}
export function checkDetail(id) {
    return request({
        method:'get',
        url:`/api/financeBillCollection/certificateDetail/${id}`
    })
}

//导出
export function exportExcel(params) {
    return request({
        method:'get',
        url:'/api/financeBillCollection/exportExcel',
        params
    });
}

//查看下载报告
export function exportRecort() {
    return request({
        url:'/api/financeBillCollection/exportRecord',
        method:'get',
    });
}